<template>
  <section class="add-amenities-slider">
    <div class="row flex-grow">

      <Loader :loading="showLoader" />
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"> Edit Banner</h4>
            <form class="forms-sample" @submit.prevent="editLifestyle">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="lifestyle.title" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import { sliderApi } from "../api";
export default {
  name: 'edit-lifestyle',
  components: {
     Loader,
  },
  data() {
    return {
      showLoader: false,
      lifestyle: {
        title: "",
      },

    };
  },
  mounted() {
    this.LifestyleById();
  },
  methods: {
    async LifestyleById() {
      this.showLoader = true;
      const { data } = await sliderApi.getLifestyleById(this.$route.params.id);
      this.lifestyle = data.data.data;
      this.showLoader = false;
    },


    async editLifestyle() {
      this.showLoader = true;
      const data = await sliderApi.updateLifestyle(this.$route.params.id, {
        ...this.lifestyle,
      });
      this.LifestyleById();
      this.showLoader = false;
      if (data.status == 200) {
        
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
      this.$router.push("/lifestyle");
    },
  }
}
</script>